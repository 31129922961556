<template>
  <div class="verifyCode">
    <button :class="['verify',disabled?'noDrop':'']" :disabled="disabled" @click="getVerify">
      {{ verifyText }}
      <template v-if="disabled == true">{{ verifyText2 }}</template>
    </button>
  </div>
</template>

<script>
export default {
  name: 'verifyCode',
  props: {
    mob: {
      type: String
    },
    event: {
      type: [Number, String]
    }
  },
  data () {
    return {
      verifyText: '获取验证码',
      verifyText2: '',
      disabled: false
    };
  },
  methods: {
    getVerify () {
      if (this.mob.length < 11) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      if (!this.$regs.isPhone.test(this.mob)) {
        this.$message.error('手机格式不正确');
        return;
      }
      this.disabled = true;
      this.verifyText = '请稍候...';
      this.verifyText2 = '';

      this.$api
        .sendSms({
          event: this.event,
          mobile: this.mob
        })
        .then(res => {
          if (res.code == 1) {
            this.countDown(10);
          } else {
            this.disabled = false;
            this.verifyText = '获取验证码';
            this.$message.error(res.msg);
          }
        })
        .catch(err => {
          this.$message.error(err);
        });
    },
    countDown (seconds) {
      this.verifyText = `已发送`;
      var auth_timetimer = setInterval(() => {
        if (seconds > 0) {
          this.disabled = true;
          this.verifyText = `${seconds}s`;
          this.verifyText2 = '后可重新发送';
          --seconds;
        } else {
          this.verifyText = '重新获取';
          this.disabled = false;
          clearInterval(auth_timetimer);
        }
      }, 1000);
    }
  }
}
</script>

<style lang="scss">
.verifyCode {
  width: 100%;
  button {
    width: 100%;
    height: 33px;
    border: none;
    background-color: #3f63ff;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px 4px 4px 4px;
  }
  .noDrop {
    cursor: no-drop;
  }
}
</style>
