<template>
  <div class="login">

    <div class="login_page">
      <div class="logo_box">
        <div class="logo">
          <img src="../../assets/logo.png" alt="">
        </div>
        <div class="logo_text">
          中国上海建设网
        </div>
      </div>
      <div class="center_box">
        <div class="left_text_box">
          <h1>智慧建设网综合平台</h1>
          <p class="tags_text">
            招标、招工、机械租赁等一站式服务平台
          </p>
        </div>
        <div class="right_login_box">
          <div class="login_logo_box">
            <div class="login_logo">
              <img src="../../assets/logo.png" alt="">
            </div>
            <div class="login_text">
              中国上海建设网
            </div>
          </div>
          <div class="login_form_box">
            <div class="input_phone_box">
              <el-input placeholder="请输入手机号码" v-model="form.mobile" />
            </div>
            <div class="input_code_box">
              <el-input type="text" placeholder="请输入验证码" v-model="form.sms_code" />
              <div class="verify_box">
                <verifyCode :mob="form.mobile" event="login"></verifyCode>
              </div>
            </div>
            <div class="login_tags_box">
              未注册手机号将自动注册为平台用户
            </div>
            <div class="login_btn">
              <button class="blue_btn" @click="login">登录</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapMutations } from 'vuex';
import verifyCode from '../../components/verifyCode/verifyCode.vue'
export default {
  components: {
    verifyCode
  },
  data () {
    return {
      form: {
        sms_code: '',
        mobile: ''
      }
    }
  },
  methods: {
    ...mapMutations(['SET_USER_INFO']),
    login () {
      this.$api.codeLogin(this.form).then(res => {
        console.log(res, '登录结果');
        if (res.data.token) {
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('refresh_token', res.data.refresh_token);
          this.getUserInfo()
        }
      })
    },
    getUserInfo () {
      this.$api.getMemberInfo().then(res => {
        localStorage.setItem('userInfo', JSON.stringify(res.data));
        this.SET_USER_INFO(res.data);
        this.$router.replace({ path: '/home' });
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  height: calc(100vh - 250px);
  box-sizing: border-box;
}
.login_page {
  background: url("../../assets/img/login/login_bg.png") no-repeat;
  background-size: 100% 100%;
  .logo_box {
    display: flex;
    align-items: center;
    padding: 22px 74px;
    .logo {
      width: 40px;
      height: 40px;
    }
    .logo_text {
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      margin-left: 8px;
    }
  }
  .center_box {
    width: 1200px;
    height: calc(100vh - 324px);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left_text_box {
      font-weight: 500;
      color: #ffffff;
      padding-left: 130px;
      h1 {
        font-size: 50px;
        margin-bottom: 30px;
      }
      p {
        font-size: 18px;
        margin-bottom: 130px;
      }
    }
    .right_login_box {
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      padding: 22px 20px 40px;
      .login_logo_box {
        margin-bottom: 20px;
        text-align: center;
        color: #3f63ff;
        font-weight: 500;
        .login_logo {
          width: 100px;
          height: 100px;
          margin: 0 auto;
        }
        .login_text {
          font-size: 18px;
        }
      }
      .login_form_box {
        .input_phone_box {
          margin-bottom: 20px;
        }
        .input_code_box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 56px;
          .verify_box {
            width: 130px;
            flex: none;
            margin-left: 10px;
          }
        }
        .login_tags_box {
          font-size: 13px;
          font-weight: 400;
          color: #3f63ff;
          margin-bottom: 16px;
          text-align: center;
        }
        .login_btn {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
</style>